<template>
  <div class="view-fees-receipt" >
    <FeeReceiptTable
      v-if="receipt"
      id="fee-receipt-table"
      :receipt="receipt"
      :copyName="`STUDENT COPY`"
    />
    <!-- <FeeReceiptTable
      v-if="receipt && printTwoCopies"
      id="fee-receipt-table"
      :receipt="receipt"
      :copyName="`${printTwoCopies ? 'PARENT COPY' : ''}`"
    /> -->
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import endpoints from "@api/repository";
import api from "@api/index";
import FeeReceiptTable from "@components/fees/FeeReceiptTable";
import moment from "moment";

export default {
  name: "ViewFeeReceipt",
  data() {
    return {
      receipt: null,
      printTwoCopies: false,
    };
  },
  components: {
    FeeReceiptTable,
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "currentInstitution",
      "accessToken",
      "isLoading",
    ]),
    essentials() {
      return {
        accessToken: this.accessToken,
        setLoading: this.setLoading,
        handleErrorsFunction: this.handleApiErrors,
      };
    },
  },
  methods: {
    ...mapActions(["setLoading", "unsetCurrentUser"]),
    moment: moment,
    async getReceiptData() {
      this.receipt = await api.call(
        this.essentials,
        endpoints.getFeeReceiptDetails + this.$route.params.rid + "/"
      );
      document.title = `${
        this.receipt.fee_account.student.full_name
      } - (${moment(new Date()).format("D MMM YYYY, hh:mm")}) | Fee Receipt`;

      // setTimeout(print(), 2000);
      window.onfocus = function () {
        window.close();
      };
    },

    docReady(fn) {
      // see if DOM is already available
      if (
        document.readyState === "complete" ||
        document.readyState === "interactive"
      ) {
        // call on next available tick
        setTimeout(fn, 500);
      } else {
        document.addEventListener("DOMContentLoaded", fn);
      }
    },
  },

  async created() {
    this.printTwoCopies = this.$route.params.count == "2";
    await this.unsetCurrentUser();
    await this.getReceiptData();
    var self = this;
    self.docReady(print);
    // setTimeout(() => self.docReady(print), 500)
    // print();
  },
};
</script>

<style type="text/css" media="print" scoped>
@page {
  size: auto; /* auto is the initial value */
  margin: 0; /* this affects the margin in the printer settings */
}
</style>